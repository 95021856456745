.with_search {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.with_search > h6 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: rgba(18, 18, 18, 0.9);
  margin-bottom: 0;
}

.search_container {
  margin: 0;
  display: flex;
  width: 90%;
}

.search_container_input {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.search_container_input > input,
.search_container_input > input:focus {
  height: 40px;
  background: #ffffff;
  box-shadow: 1px 2px 32px rgba(0, 0, 0, 0.04);
  border-radius: 6px;
  border: 0.029vw solid rgba(0, 0, 0, 0.15);
  padding-left: 3.075vw;
  outline: transparent;
  margin: 0;
  width: 100%;
}

.searchIcon {
  position: absolute;
  width: 1.5vw;
  top: 28%;
  left: 0.75rem;
}

.search_container_filter {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
  width: 70%;
}

.vehicle_list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 2%;
  margin-top: 0.6rem;
}

.vehical_card_container {
  width: 100%;
  min-height: 100px;
  background: #ffffff;
  border: 0.4px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  font-family: "Inter";
  font-style: normal;
  margin-bottom: 1rem;
  display: flex;
  align-items: flex-start;
  padding: 0.8rem;
  cursor: pointer;
  gap: 1rem;
}
.vehical_card_text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.vehical_card_text > h6 {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #121212;
  margin-bottom: 0.3rem;
}
.vehical_card_text > p {
  margin-bottom: 0.3rem !important;
  font-weight: 400;
  font-size: 11px;
  line-height: 12px;
  color: rgba(18, 18, 18, 0.6);
}
.vcardContainer {
  width: 49%;
}
.vcardContainer > a {
  text-decoration: none;
}
.vcardContainer > a:hover {
  text-decoration: none;
}

/* ---------------------------------------------- */

.mainContainer {
  width: 100%;
  margin-top: -2vw;
  min-height: 32.943vw;
  background-color: #ffffff;
  border: 0.037vw solid #cacaca;
  border-radius: 0.439vw;
  border-top-left-radius: 1.75rem;
  border-top-right-radius: 1.75rem;
  position: relative;
}

.class_header_container {
  width: 100%;
  height: 13rem;
  background: #ffffff;
  border-right: 0.5px solid #cacaca;
  display: inline-flex;
  gap: 1rem;
}

.class_header_image {
  width: 50%;
  height: 13rem;
}

.class_header_info p h4 h6 {
  margin: 0;
}
.class_header_info {
  padding: 1.25rem 0 3.4rem 0;
  display: flex;
  flex-direction: column;
  /* flex-wrap: wrap; */
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
}
.class_header_info_bottom {
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
  align-items: flex-end;
  width: 100%;
  padding-right: 3.1rem;
  margin-top: 0.85rem;
}
.class_header_info_add_student {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}
.class_header_info_add_student > img {
  width: 36px;
  height: 36px;
}
.class_header_info_detail > h4,
.class_header_info_detail > h6,
.class_header_info_detail > p,
.class_header_info_conut > p,
.class_header_info_conut > p > span,
.class_header_info_add_student > p {
  font-family: "Inter";
  font-style: normal;
}
.class_header_info_detail > h4 {
  font-weight: 700;
  font-size: 1rem;
  line-height: 20px;
  color: #000;
}

.class_header_info_detail > h6 {
  font-weight: 500;
  font-size: 0.9rem;
  line-height: 20px;
  color: #000;
  margin-bottom: 0.2vw;
}
.class_header_info_detail > p {
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: rgba(18, 18, 18, 0.9);
  margin-bottom: 0;
}
.class_header_info_conut {
  display: flex;
  gap: 17px;
}
.class_header_info_conut > p {
  font-weight: 500;
  font-size: 0.9rem;
  line-height: 16px;
  color: rgba(18, 18, 18, 0.6);
  margin-bottom: 0;
}
.class_header_info_conut > p > span {
  font-weight: 600;
  font-size: 0.9rem;
  line-height: 17px;
  color: #000;
  margin-right: 4px;
}
.class_header_info_add_student > p {
  font-weight: 400;
  font-size: 0.9rem;
  line-height: 17px;
  color: #436dfa;
  margin-bottom: 0;
}

.blue_line_container {
  width: 100%;
  height: 3.2rem;
  background-color: var(--tab-active-bg-color);
  border-radius: 6px 6px 0px 0px;
}

/* --------------------------Passanger list-------------------- */

.transpotation_container {
  width: 100%;
  border-top: 0.5px solid #cacaca;
  border-radius: 6px;
}

.add_new_category {
  font-family: "Inter";
  font-style: normal;
  margin: 0;
  font-weight: 400;
  font-size: 0.9rem;
  line-height: 17px;
  color: #436dfa;
  display: grid;
  place-items: end;
  cursor: pointer;
  margin-bottom: 0.5rem;
}

.transpotation_head {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.8rem 0.5rem;
  font-family: "Inter";
  font-style: normal;
  width: 100%;
}
.transpotation_head > h6 {
  font-weight: 500;
  font-size: 0.9rem;
  line-height: 17px;
  color: #121212;
  margin-bottom: 0.2rem;
}

.transpotation_card {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.8rem 0.5rem;
  font-family: "Inter";
  font-style: normal;
  width: 100%;
  cursor: pointer;
}
.transpotation_card_detail {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.9rem;
}
.transpotation_card_detail_text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.transpotation_card_detail > img {
  width: 2.8rem;
  height: 2.8rem;
  border-radius: 50%;
}
.transpotation_card_detail_text > h6,
.joining_date {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.9);
  margin-bottom: 0.2rem;
}

.transpotation_card_detail_text > p {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 0.2rem !important;
}

.transpotation_card_detail_other {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.transpotation_card_detail_other_inner {
  display: flex;
  align-items: center;
  gap: 0.8rem;
}

.transpotation_card_detail_other_inner > p {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: rgba(0, 0, 0, 0.9);
  margin-bottom: 0.2rem !important;
}

.add_plus_icon {
  cursor: pointer;
}

/* ---------------------------------------------- */

@media only screen and (max-width: 992px) {
  .vcardContainer {
    width: 100%;
  }
}
