.error {
  height: 100vh;
  width: 100vw;
  background-color: white;
  display: grid;
  place-items: center;
}

.errordiv {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.error .errorimg {
  max-height: 70vh;
  user-select: none;
}

.goback {
  cursor: pointer;
}
