.remainingFees {
  background: #ffffff;
  border-radius: 0.439vw;
  width: 100%;
  min-height: 50vh;
}

.remainingFeesTop {
  padding: 1.977vw 1vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.5px solid #cacaca;
  margin-bottom: 0.5rem;
}

.remainingFeesTop h6 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1.171vw;
  line-height: 19px;
  text-align: right;
  margin-bottom: 0 !important;
  color: rgba(18, 18, 18, 0.9);
}

.remainingFeesTop img {
  height: 1.025vw;
  cursor: pointer;
}
/* --------------------------------- */
.electionCotainer {
  display: flex;
  justify-content: space-between;
}

.eventlistDetail {
  width: 48%;
  flex-direction: column;
  justify-content: flex-start;
}
.eventlistDetail {
  display: none !important;
}

.eventlist {
  margin-top: 1vw;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  padding: 1rem;
}

.eventDetails,
.eventlist {
  /* display: none; */
}
.eventCardContainer {
  width: 48%;
}

.eventCardContainerDetail {
  width: 100% !important;
}

.noData {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.noData > img {
  height: 3rem;
}

.noData > h6 {
}

.loader {
  display: grid;
  place-items: center;
  height: 100%;
  width: 100%;
}

.electionDetailsCotainer {
  width: 100%;
  padding: 1rem;
}

.electionDetailsCotainerDetails {
  width: 100% !important;
}
.election_card_title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 1.2vw;
  cursor: pointer;
}

.election_card_title > img,
.candidate_member > img,
.candidate_member_normal > img,
.candidate_member_result > img {
  width: 42px;
  height: 42px;
  border-radius: 50%;
}

.election_card_title > img,
.candidate_member > img,
.candidate_member_normal > img,
.candidate_member_result > img {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
}

.create_new_elections {
  font-family: var(--font-family);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-500);
  font-size: 0.9rem;
  line-height: 19px;
  color: var(--color-black-text);
  margin: 10px 0 20px 0;
}

.create_new_elections_hr {
  margin-left: -15px;
  margin-right: -15px;
  /* border: 0 solid #c4c4c4; */
}
.create_new_elections_hr1 {
  border: 3px solid #c4c4c4;
}
.create_new_elections_hr {
  margin-left: -15px;
  margin-right: -15px;
  /* border: 0 solid #c4c4c4; */
}
.create_new_elections_hr1 {
  border: 3px solid #c4c4c4;
}

.election_card {
  cursor: pointer;
  padding: 1rem;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25), 1px 2px 32px rgba(0, 0, 0, 0.02);
  margin-bottom: 0.75rem;
}

.election_card_title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 1.2vw;
  cursor: pointer;
}

.election_card_title > img,
.candidate_member > img,
.candidate_member_normal > img,
.candidate_member_result > img {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
}

.election_card_info > p {
  font-family: var(--font-family);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-400);
  font-size: 0.8rem;
  color: rgba(18, 18, 18, 0.8);
  margin-bottom: 0 !important;
}

.election_card_info > p > span {
  font-weight: var(--font-weight-500);
  font-size: 0.7rem;
  color: rgba(18, 18, 18, 1);
}

.peventCardtop {
  padding: 0.75rem;
  display: flex;
  gap: 1rem;
  align-items: center;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.15);
}

.issuedBookItemMidright > h6 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 0.9rem;
  line-height: 17px;
  /* identical to box height */
  margin-bottom: 0.3rem;
  color: #121212;
}

.issuedBookItemMidright > p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 17px;
  /* identical to box height */
  margin-bottom: 0 !important;
  color: rgba(18, 18, 18, 0.5);
}

.viewRowh6 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 0.9rem !important;
  line-height: 17px;
  /* identical to box height */

  color: rgba(18, 18, 18, 0.9);
}
.desh6 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1rem !important;
  line-height: 130%;
  /* identical to box height */

  color: rgba(81, 80, 80, 0.9);
}

.viewRowp {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 17px;
  /* identical to box height */
  margin-bottom: 0.3vw !important;
  color: rgba(18, 18, 18, 0.5);
}

.viewRow {
  margin-bottom: 1.5rem;
}
.peventleftbody {
  padding: 1rem;
}
@media only screen and (max-width: 768px) {
  .eventlist {
    flex-direction: column;
  }
  .eventCardContainer {
    width: 100%;
    margin-bottom: 0.5rem;
  }
}
