/* ----------------------------------------   */

.main {
  width: 100%;
  display: flex;
  gap: 2%;
  /* padding: 2rem 8rem; */
  background-color: #f5f5f5;
  min-height: 100vh;
  margin-top: 0.1rem;
}

.fineside {
  width: 24%;
}

.finance_container_other {
  width: 840px;
  min-height: 50vh;
}

.finance_container {
  width: 74%;
  min-height: 50vh;
  background-color: #ffffff;
  border: 0.037vw solid #cacaca;
  border-radius: 0.439vw;
}
.finance_tab_tabs {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.finance_tab_tabs {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.transportdetails_container {
  width: 100%;
  padding: 1rem;
}

.institute_hostel_container {
  padding: 1rem;
}

.institute_hostel_container a {
  text-decoration: none;
}
