.input_without_icon,
.input_without_icon:focus {
  background: #ffffff;
  border: 0.5px solid rgba(0, 0, 0, 0.12);
  box-shadow: 1px 2px 32px rgba(0, 0, 0, 0.05);
  border-radius: 0.3rem;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 0.9rem;
  line-height: 17px;
  color: rgba(18, 18, 18, 0.8);
  outline: none;
}

.input_without_icon::placeholder {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 0.9rem;
  line-height: 17px;
  /* identical to box height */

  color: rgba(18, 18, 18, 0.5);
}

.input_without_icon:focus {
  border: 0.5px solid rgba(0, 0, 0, 0.42) !important;
}

.input_without_icon_label {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 0.9rem;
  line-height: 17px;
  /* identical to box height */
  margin-bottom: 0.3rem;
  color: rgba(18, 18, 18, 0.8);
}

.labelspan {
  color: #ed4a4a;
}
