.searchbar {
  position: relative;
}

.searchbar > input {
  height: 2.8vw;
  background: #ffffff;
  border: 0.5px solid rgba(0, 0, 0, 0.08);
  box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.02);
  border-radius: 20px;
  width: 25vw;
  padding-left: 45px;
  position: relative;
  outline: none;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 1.1vw;
  line-height: 16px;

  color: #818181;
}

.searchbar > input::placeholder {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 1vw;
  line-height: 16px;

  color: #818181;
  position: absolute;
  top: 0.8vw;
}
.searchbar > img {
  position: absolute;
  left: 6%;
  bottom: 24% !important;
  height: 1.2vw;

  bottom: 27%;
}
.searchbar > img:hover {
  cursor: pointer;
}

@media only screen and (max-width: 1024px) {
  .searchbar > input {
    height: 3.5vw;
    font-size: 1.2vw;
  }

  .searchbar > input::placeholder {
    font-size: 1.3vw;
    top: 1vw;
  }

  .searchbar > img {
    bottom: 30% !important;
  }
}
