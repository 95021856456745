.navbar {
  position: sticky;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 100;
  background-color: white;
}

.editContainer {
  width: 82.577vw;
  display: flex;
  margin: 0.84vw auto 0 auto;
  gap: 1.25vw;
}
.bgContainer {
  background-color: #f5f5f5;
}
/* ----------------------------------------   */

.main {
  width: 100%;
  display: flex;
  gap: 2%;
  /* padding: 2rem 8rem; */
  background-color: #f5f5f5;
  min-height: 100vh;
  margin-top: 0.1rem;
}

.fineside {
  width: 24%;
}

.finance_container_other {
  width: 840px;
  min-height: 50vh;
}

.finance_container {
  width: 74%;
  min-height: 50vh;
  background-color: #ffffff;
  border: 0.037vw solid #cacaca;
  border-radius: 0.439vw;
}
.finance_tab_tabs {
  width: 100%;
  display: flex;
  flex-direction: row;
}
