.navright {
  display: flex;
  align-items: center;
  position: relative;
}

.left {
  display: flex;
  align-items: center;
  gap: 2vw;
}

.mail .noti {
  position: relative !important;
}

.imageRound {
  border-radius: 50%;
}

.noti {
  cursor: pointer;
}

.noticount,
.mailcount {
  position: absolute;
  top: 1.3%;
  /* margin-left: 2.3vw;
  height: 1vw;
  width: 1vw;
  background-color: #197dda; */
}

.noticount {
}

.user {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  margin-top: 0.5vw;
}

.user1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: 0.5vw;
}
.user h6,
.user1 h6 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 1vw;
  line-height: 19px;
  /* text-align: right; */

  color: #121212;
}
.user1 h6 {
  margin-bottom: 0 !important;
}

.user p,
.user1 p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 0.8vw;
  line-height: 17px;
  /* identical to box height */
  margin-top: -0.5vw;
  color: rgba(18, 18, 18, 0.6);
}

.user1 p {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}

.imgContainer {
  width: 3vw;
  height: 3vw;
  border-radius: 50%;
  cursor: pointer;
}

.imgContainer img {
  width: 100%;
  height: 100%;
}

.bright img,
.mail img,
.noti img {
  width: 3vw;
  height: 3vw;
  border-radius: 50%;
}

@media only screen and (max-width: 1024px) {
  .imgContainer,
  .bright img,
  .mail img,
  .noti img {
    width: 3.5vw;
    height: 3.5vw;
    border-radius: 50%;
  }

  .user h6 {
    margin-top: 0.5vw;
    font-size: 1.1vw;
  }

  .user p {
    margin-top: -0.9vw;
    font-size: 0.9vw;
  }
}

.menu {
  /* position: absolute;
  top: 4.2vw;
  right: 0; */
  width: 20vw;
  background: #fff;
  border-radius: 6px;

  box-shadow: -1px 6px 22px 0px rgba(0, 0, 0, 0.75);
}

.menutop {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1vw;
  padding: 0.5vw;
  position: relative;
  border-bottom: 0.3px solid rgb(213, 207, 207);
}

.menudots {
  position: absolute;
  top: 1.5vw;
  right: 1vw;
  cursor: pointer;
}

.bottom {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  padding: 1vw;
}

/* ---------------------------------------------- */

.item {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  gap: 1vw;
  /* margin-top: 1vw; */
  width: 100%;
  padding: 10px;
}

.item img {
  height: 1.7vw;
  margin-top: -0.5vw;
}

.item h6 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 1.2vw;
  line-height: 19px;

  color: #121212;
}

.item:hover {
  background-color: #f5f0f6;
  cursor: pointer;
}

@media only screen and (max-width: 920px) {
  .item img {
    height: 2.7vw;
    margin-top: -0.5vw;
  }
}

.editMenu {
  background-color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 6px;
  box-shadow: -1px 6px 22px 0px rgb(0 0 0 / 25%);
  cursor: pointer;
}
