.department_container {
  padding: 1rem;
  background-color: #ffffff;
  min-height: 70vh;
}

.department_card_container {
  padding: 14px 11px;
  width: 100%;
  height: 70px;
  background-color: #ffffff;
  border: 0.4px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 19px;
  margin-bottom: 19px;
  cursor: pointer;
}
.department_card_container:hover {
  background-color: rgba(75, 26, 133, 0.1);
}
.department_card_image {
  height: 48px;
  width: 48px;
}
.department_card_name {
  font-weight: var(--font-weight-500);
  font-size: var(--font-size-14);
  line-height: var(--line-height-17);
  color: #121212;
}

.department_card_head {
  font-weight: var(--font-weight-400);
  font-size: var(--font-size-13);
  line-height: var(--line-height-17);
  color: rgba(18, 18, 18, 0.8);
}

.department_card_title {
  font-weight: var(--font-weight-400);
  font-size: 11px;
  line-height: var(--line-height-17);
  color: rgba(18, 18, 18, 0.6);
}
