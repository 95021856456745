.itemIconContainer {
  display: flex;
  gap: 8%;
  padding: 1rem 1.5rem;
  flex-wrap: wrap;
  background-color: #fff;
  min-height: 70vh;
}

.itemIcon {
  width: 46%;
  cursor: pointer;
}

.noData {
  display: grid;
  place-items: center;
  min-height: 50vh;
}

/* ----------------InsCareerCard----------------------- */
.insCareerCardInner {
  position: relative;
}
.insCareerCard {
  background: #ffffff;
  border: 0.4px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0.75rem;
  padding: 0.5rem;
  display: flex;
  align-items: flex-start;
  gap: 0.75rem;
  margin-bottom: 0.75rem;
}

.insCareerCard > img {
  height: 4.75rem;
}

.insCareerCardsubject {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 0.85rem;
  line-height: 16px;
  margin-bottom: 0.15rem !important;

  color: #121212;
}

.insCareerCarddept {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 0.85rem;
  line-height: 18px;
  margin-bottom: 0.15rem !important;

  color: rgba(18, 18, 18, 0.8);
}

.insCareerCarddesig {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 18px;
  margin-bottom: 0.15rem !important;

  color: rgba(18, 18, 18, 0.6);
}

.insCareerCardtext {
  flex: 1;
}

/* ----------------InsCareerCard----------------------- */

.dots {
  height: 1.3rem !important;
  margin-top: 0.5rem;
  cursor: pointer;
  visibility: hidden;
}

.menudots {
  height: 1.3rem !important;
  position: absolute;
  cursor: pointer;
  right: 0.5rem;
  top: 0.75rem;
  z-index: 20;
}
