.search_backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.75);
}

.search_card {
  position: fixed;
  z-index: 100000;
  width: 480px;
  height: 602px;
  left: 156px;
  top: 0px;
  background: #ffffff;
  border: 0.5px solid #cacaca;
  box-shadow: -2px 0px 12px rgba(0, 0, 0, 0.15),
    2px 4px 12px rgba(0, 0, 0, 0.15);
  overflow: hidden;
}
.search_container {
  margin: 14px 28px;
  display: flex;
}

.search_container_text {
  position: relative;
  display: flex;
  align-items: center;
}
.search_container_text > input,
.search_container_text > input:focus {
  margin-left: 10px;
  width: 375px;
  height: 40px;
  background: rgba(28, 2, 59, 0.07);
  box-shadow: 1px 2px 32px rgba(0, 0, 0, 0.04);
  border-radius: 6px;
  border: none;
  padding-left: 3.075vw;
  outline: none;
}

.searchIcon {
  position: absolute;
  margin-left: 1.3vw;
  width: 1.5vw;
}

.search_container_back {
  cursor: pointer;
}
