.with_search {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.with_search > h6 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 0.9rem;
  line-height: 19px;
  color: rgba(18, 18, 18, 0.9);
  margin-bottom: 0;
}

.search_container {
  margin: 0;
  display: flex;
  width: 50%;
}

.search_container_input {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}

.search_container_input > input,
.search_container_input > input:focus {
  min-width: 100%;
  height: 40px;
  background: #ffffff;
  box-shadow: 1px 2px 32px rgba(0, 0, 0, 0.04);
  border-radius: 6px;
  border: 0.029vw solid rgba(0, 0, 0, 0.15);
  padding-left: 3.075vw;
  outline: transparent;
}
.searchIcon {
  position: absolute;

  width: 1rem;
  top: 30%;
  left: 3%;
}
.hostel_warden_card {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  min-height: 66px;
  background: #ffffff;
  border: 0.4px solid rgba(0, 0, 0, 0.15);
  box-shadow: -1px -1px 10px rgba(0, 0, 0, 0.02),
    1px 2px 32px rgba(0, 0, 0, 0.02);
  border-radius: 12px;
  font-family: "Inter";
  font-style: normal;
  gap: 1.5rem;
  padding: 0 1rem;
  margin-top: 0.7rem;
}

.hostel_warden_card > img {
  width: 42px;
  height: 42px;
  border-radius: 50%;
}

.warden_card_text > h6 {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #121212;
  margin-bottom: 0.2rem;
}
.warden_card_text > p {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: rgba(18, 18, 18, 0.5);
  margin-bottom: 0.2rem !important;
}

.hostelite_container {
  width: 100%;
  min-height: 66px;
  background: #ffffff;
  border: 0.4px solid rgba(0, 0, 0, 0.15);
  box-shadow: -1px -1px 10px rgba(0, 0, 0, 0.02),
    1px 2px 32px rgba(0, 0, 0, 0.02);
  border-radius: 12px;
  font-family: "Inter";
  font-style: normal;
  margin-bottom: 1rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1.2rem;
  padding: 0.8rem;
}
.hostelite_container > img {
  width: 42px;
  height: 42px;
  border-radius: 50%;
}

.hostelite_container_inner {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.hostelite_container_inner_text > h6 {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #121212;
  margin-bottom: 0.2rem;
}

.hostelite_container_inner_text > p {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: rgba(18, 18, 18, 0.5);
  margin-bottom: 0.2rem !important;
}

.announcement_card {
  width: 100%;
  min-height: 66px;
  background: #ffffff;
  border: 0.4px solid rgba(0, 0, 0, 0.15);
  box-shadow: -1px -1px 10px rgba(0, 0, 0, 0.02),
    1px 2px 32px rgba(0, 0, 0, 0.02);
  border-radius: 12px;
  font-family: "Inter";
  font-style: normal;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0.8rem;
  cursor: pointer;
}

.announcement_card:hover {
  background-color: rgba(75, 26, 133, 0.1);
}

.announcement_card > h6 {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #121212;
  margin-bottom: 0.2rem;
}

.announcement_card > p {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: rgba(18, 18, 18, 0.5);
  margin-bottom: 0.2rem !important;
}

.rules_card {
  width: 100%;
  min-height: 5rem;
  background: #ffffff;
  border: 0.4px solid rgba(0, 0, 0, 0.15);
  box-shadow: -1px -1px 10px rgba(0, 0, 0, 0.02),
    1px 2px 32px rgba(0, 0, 0, 0.02);
  border-radius: 12px;
  padding: 1rem;
  margin-top: 0.8rem;
  font-family: "Inter";
  font-style: normal;
}
.rules_card_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.rules_card_title > h6 {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #121212;
}
.rules_card > p {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: rgba(18, 18, 18, 0.8);
  width: 95%;
}
.download_attachment {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #001a72;
}

.remainingFeesBottom {
  background: #ffffff;
  width: 100%;
}
.itemsContainer {
  min-height: 60vh;
}
.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.3px solid rgb(222, 214, 214);
  cursor: pointer;
  padding: 1vw 2.955vw;
  border-bottom: 0.5px solid #eaeaea;
}
.item:hover {
  background: rgba(75, 26, 133, 0.1);
}
.item img {
  height: 2.5vw;
  width: 2.5vw;
  border-radius: 50%;
}
.subItem {
  display: flex;
  gap: 1.5vw;
  align-items: center;
}

.text p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 0.878vw;
  line-height: 15px;
  /* identical to box height */
  text-align: left;

  color: #121212;
}

.text h6 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1vw;
  line-height: 17px;
  /* identical to box height */
  margin-bottom: 0.3vw;
  color: #121212;
  text-align: left;
}
.menu_icon {
  width: 1.5rem !important;
  cursor: pointer;
  position: absolute;
  right: 3%;
}

.applicationTop {
  padding: 0.75rem;
}
