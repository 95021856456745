.main {
  width: 100%;
  display: flex;
  gap: 2%;
  /* padding: 2rem 8rem; */
  background-color: #f5f5f5;
  min-height: 100vh;
  margin-top: 0.1rem;
}

.stats {
  margin-bottom: 2vw;
  position: sticky;
  top: 95px;
  margin-top: 17px;
}

.stats1 {
  min-height: 60vh;
  margin-bottom: 2vw;
  position: sticky;
  top: 95px;
  margin-top: 7px;
  flex: 1;
}

.finance_container {
  width: 74%;
  min-height: 50vh;
  background-color: #ffffff;
  border: 0.037vw solid #cacaca;
  border-radius: 0.439vw;
}

.fineside {
  width: 24%;
}

.finance_container_other {
  width: 840px;
  min-height: 50vh;
}
.finance_tab_tabs {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.item {
  width: 100%;
  display: flex;
  gap: 3vw;
  align-items: center;
  cursor: pointer;
  justify-content: flex-start;
  margin-top: 3vw;
  flex-wrap: wrap;
  margin-bottom: 4vw;
  padding-left: 4vw;
}

.item > a {
  text-decoration: none;
}

.itemIcon > p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 1vw;
  line-height: 17px;
  margin-bottom: 0 !important;
  color: #121212;
  text-align: center;
}
.itemIcon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 11vw;
  height: 11vw;
  background: #ffffff;
  border: 0.4px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 1px 2px 32px rgba(0, 0, 0, 0.02);
  border-radius: 12px;
  gap: 2vw;
}

.itemIcon > img {
  height: 3.5vw;
  width: 3.5vw;
}
.itemIcon:hover {
  background: rgba(75, 26, 133, 0.1);
}
