.studentMCQ {
  width: 100%;
}
.mcqToptabs {
  width: 100%;
  display: flex;
  background: #e9ecf7;
  box-shadow: -1px -1px 10px rgba(0, 0, 0, 0.02),
    1px 2px 32px rgba(0, 0, 0, 0.02);
  border-radius: 0.2rem;
  width: 100%;
  padding: 0.5rem 0.5rem;
}

.mcqTop {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.75rem 1.25rem;
}

.mcqTopItem {
  width: 50%;
  display: grid;
  place-items: center;
  padding: 0.65rem 1rem;
  cursor: pointer;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 15px;
  text-align: center;

  color: rgba(18, 18, 18, 0.8);
}

.active {
  background: #ffffff !important;
  -webkit-box-shadow: -1px 7px 5px -4px rgba(0, 0, 0, 0.35);
  -moz-box-shadow: -1px 7px 5px -4px rgba(0, 0, 0, 0.35);
  box-shadow: -1px 7px 5px -4px rgba(0, 0, 0, 0.35);
  border-radius: 0.3rem !important;
}
