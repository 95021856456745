.overlay_card {
  position: fixed;
  z-index: 1000;
  min-width: 100px;
  min-height: 100px;
  background: #ffffff;
  border: 0.5px solid #cacaca;
  box-shadow: -2px 0px 12px rgba(0, 0, 0, 0.15),
    2px 4px 12px rgba(0, 0, 0, 0.15);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
}
