.mainContainer {
  width: 100%;
  margin-top: -2vw;
  min-height: 32.943vw;
  background-color: #ffffff;
  border: 0.037vw solid #cacaca;
  border-radius: 0.439vw;
  border-top-left-radius: 1.75rem;
  border-top-right-radius: 1.75rem;
  position: relative;
}

.hosteliteContainer {
  padding: 0.75rem;
}

.unit_room_container {
  padding: 1rem;
}

.unit_room_container a {
  text-decoration: none;
}

.unit_room_container_box {
  width: 100%;
  display: flex;
  gap: 1%;
  margin-top: -1.3rem;
  position: relative;
  min-height: 24vw;
}
.unit_room_container_box_inner {
  width: 100%;
  background: #ffffff;
  border: 0.5px solid #cacaca;
  border-top-left-radius: 1.5rem;
  border-top-right-radius: 1.5rem;
  padding-bottom: 2vw;
  padding: 1rem;
}
.unit_room_card_container {
  width: 100%;
  min-height: 66px;
  background: #ffffff;
  border: 0.4px solid rgba(0, 0, 0, 0.15);
  box-shadow: -1px -1px 10px rgba(0, 0, 0, 0.02),
    1px 2px 32px rgba(0, 0, 0, 0.02);
  border-radius: 12px;
  font-family: "Inter";
  font-style: normal;
  padding: 0.7rem 1rem;
  margin-bottom: 0.6rem;
  cursor: pointer;
}
.unit_room_card_lines {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.unit_room_card_lines > h6 {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #121212;
  margin-bottom: 0.3rem;
}
.unit_room_card_lines > p {
  margin-bottom: 0 !important;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: rgba(18, 18, 18, 0.5);
}

.hostelite_container {
  width: 100%;
  min-height: 66px;
  background: #ffffff;
  border: 0.4px solid rgba(0, 0, 0, 0.15);
  box-shadow: -1px -1px 10px rgba(0, 0, 0, 0.02),
    1px 2px 32px rgba(0, 0, 0, 0.02);
  border-radius: 12px;
  font-family: "Inter";
  font-style: normal;
  margin-bottom: 1rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1.2rem;
  padding: 0.8rem;
}
.hostelite_container > img {
  width: 42px;
  height: 42px;
  border-radius: 50%;
}

.hostelite_container_inner {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.hostelite_container_inner_text > h6 {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #121212;
  margin-bottom: 0.2rem;
}

.hostelite_container_inner_text > p {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: rgba(18, 18, 18, 0.5);
  margin-bottom: 0.2rem !important;
}
