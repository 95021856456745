.loader {
  height: 100vh;
  width: 100vw;
  background-color: #f5f5f5;
  display: grid;
  place-items: center;
}

.loaderGif {
  height: 50vh;
  width: 100%;
  display: grid;
  place-items: center;
}

.loaderGif > img {
  height: 55px;
  width: 55px;
}

.new_qviple_loader {
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 100000;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: rgba(0, 0, 0, 0.1); */
  background-color: transparent;
}
.new_qviple_loader > img {
  height: 200px;
  width: 200px;
}
.backdrop_loader {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 999;
}
.dimmer_holder {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.15);
  /* background-color: rgba(0, 0, 0, 0.03); */
  /* background-color: rgba(255, 255, 255, 0.4); */
  z-index: 999;
}

.dimmer {
  position: absolute;
  margin: auto;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 19px;
  height: 19px;
  border-radius: 100%;
  box-shadow: 19px 19px #0b1f59, -19px 19px #dfdfdf, -19px -19px #0b1f59,
    19px -19px #dfdfdf;
  -o-box-shadow: 19px 19px #0b1f59, -19px 19px #dfdfdf, -19px -19px #0b1f59,
    19px -19px #dfdfdf;
  -ms-box-shadow: 19px 19px #0b1f59, -19px 19px #dfdfdf, -19px -19px #0b1f59,
    19px -19px #dfdfdf;
  -webkit-box-shadow: 19px 19px #0b1f59, -19px 19px #dfdfdf, -19px -19px #0b1f59,
    19px -19px #dfdfdf;
  -moz-box-shadow: 19px 19px #0b1f59, -19px 19px #dfdfdf, -19px -19px #0b1f59,
    19px -19px #dfdfdf;
  animation: cssload-spin ease infinite 3.4s;
  -o-animation: cssload-spin ease infinite 3.4s;
  -ms-animation: cssload-spin ease infinite 3.4s;
  -webkit-animation: cssload-spin ease infinite 3.4s;
  -moz-animation: cssload-spin ease infinite 3.4s;
}

@keyframes cssload-spin {
  0%,
  100% {
    box-shadow: 19px 19px #17c4bb, -19px 19px #dfdfdf, -19px -19px #17c4bb,
      19px -19px #dfdfdf;
  }
  25% {
    box-shadow: -19px 19px #dfdfdf, -19px -19px #0b1f59, 19px -19px #dfdfdf,
      19px 19px #0b1f59;
  }
  50% {
    box-shadow: -19px -19px #17c4bb, 19px -19px #dfdfdf, 19px 19px #17c4bb,
      -19px 19px #dfdfdf;
  }
  75% {
    box-shadow: 19px -19px #dfdfdf, 19px 19px #4f4d49, -19px 19px #dfdfdf,
      -19px -19px #4f4d49;
  }
}
