.main {
  width: 100%;
  /* height: 87vh; */
  display: flex;
  gap: 1.5vw;

  justify-content: space-between;
}

.sidebar {
  width: 26%;
}
.rightbody {
  width: 100%;
}
.admission_tab_tabs {
  display: flex;
  flex-direction: row;
}
