.common_tab_text,
.common_card_content_header,
.common_card_content_paragraph,
.common_card_content_count,
.common_card_content_headheader,
.common_card_content_headparagraph,
.goback_container_text_go,
.common_card_container_ashead {
  font-family: var(--font-family);
  font-style: var(--font-style-normal);
  margin: 0;
}
.common_tab_container,
.common_tab_container_active {
  width: 33.1%;
  padding: 1rem;
  display: grid;
  place-items: center;
  cursor: pointer;
}

.common_tab_container {
  background-color: var(--tab-bg-color);
  color: var(--tab-color);
}
.common_tab_container_active {
  background-color: var(--tab-active-bg-color);
  color: var(--tab-active-color);
}
.common_tab_text {
  font-weight: var(--font-weight-500);
  font-size: var(--font-size-14);
  line-height: var(--line-height-17);
  color: inherit;
}

.blue_line_container {
  width: 100%;
  height: 54px;
  background-color: var(--tab-active-bg-color);
  border-radius: 6px 6px 0px 0px;
}
.goback_container {
  max-width: 100%;
  max-height: 3.5rem;
  background-color: var(--tab-active-bg-color);
  border-radius: 6px 6px 0px 0px;
  text-decoration: none;
}

.goback_container_text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  padding: 16px 23px;
  color: var(--tab-active-color);
}
.goback_container_text_go {
  margin: 0;

  font-weight: var(--font-weight-500);
  font-size: var(--font-size-18);
  line-height: 22px;
}
.common_card_wrapper {
  position: relative;
  background-color: var(--tab-active-color);
  border-radius: 18px 18px 12px 12px;
}

.common_card_container,
.common_card_container_active {
  max-height: 4.3rem;
  padding: 1.2rem;
  border: 0.4px solid rgba(0, 0, 0, 0.15);
  border-bottom: 0;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.common_card_container {
  background-color: var(--tab-active-color);
  width: 100%;
}
.common_card_container_active {
  background-color: rgba(75, 26, 133, 0.1);
}
.common_card_container_ashead {
  flex-direction: column;
  align-items: flex-start;
}
.common_card_content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 3px;
}
.common_card_content_header {
  font-weight: var(--font-weight-600);
  font-size: var(--font-size-14);
  line-height: 16px;
  color: var(--tab-color);
}
.common_card_content_paragraph {
  font-weight: var(--font-weight-400);
  font-size: var(--font-size-10);
  line-height: 12px;
  color: rgba(18, 18, 18, 0.6);
}
.common_card_content_count {
  font-weight: var(--font-weight-600);
  font-size: var(--font-size-20);
  line-height: 23px;
  color: var(--tab-color);
}
.common_card_content_headheader {
  font-weight: var(--font-weight-500);
  font-size: var(--font-size-14);
  line-height: 20px;
  color: var(--color-black-text);
}
.common_card_content_headparagraph {
  font-weight: var(--font-weight-500);
  font-size: var(--font-size-13);
  color: var(--color-grey-input-text-9);
}

.common_sidebar_background_container {
  margin-top: -0.68px;
  max-width: 100%;
  max-height: 9.25rem;
}
.common_sidebar_background_image {
  width: 100%;
}

.common_sidebar_edit {
  position: absolute;
  top: 115px;
  right: 14px;
  height: 36px;
  width: 36px;
  cursor: pointer;
}
