.logout {
  padding: 2vw;
  width: 25vw;
  background: #ffffff;
  border: 0.4px solid rgba(0, 0, 0, 0.15);
  box-shadow: -1px -1px 10px rgba(0, 0, 0, 0.02),
    1px 2px 32px rgba(0, 0, 0, 0.02);
  border-radius: 12px;
}

.title {
  display: grid;
  place-items: center;
  width: 100%;
}
.title h6,
.title h5 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  cursor: pointer;

  color: #121212;
}
.title h6:hover {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 24px;

  color: #1c023b;
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.content h6 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  cursor: pointer;
  color: rgba(18, 18, 18, 0.8);
}
