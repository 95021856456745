.navbar {
  position: sticky;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 100;
  background-color: white;
}

.editContainer {
  width: 82.577vw;
  display: flex;
  margin: 1.5rem auto 0 auto;
  gap: 1.25vw;
  min-height: calc(100vh - 70px);
}
.bgContainer {
  background-color: #f5f5f5;
}
