.staff_profile_container {
  background: #ffffff;
  border: 0.5px solid #cacaca;
  border-radius: 6px;
  margin-top: 23px;
  width: 100%;
}

.staff_header_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 18px 11px 18px;
}
.staff_header_function {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 66px;
}
.staff_header_text,
.staff_header_prev > span,
.staff_header_next > span,
.staff_header_close,
.staff_profile_photo_name,
.staff_profile_photo_index,
.each_info_container > h5,
.each_field_text,
.each_field_text_value {
  font-family: var(--font-family);
  font-style: var(--font-style-normal);
  margin: 0;
}
.staff_header_text {
  font-weight: var(--font-weight-500);
  font-size: var(--font-size-14);
  line-height: var(--line-height-17);
  color: #121212;
  cursor: pointer;
}
.staff_header_prev > span,
.staff_header_close,
.staff_header_next > span,
.staff_header_prev,
.staff_header_next {
  font-weight: var(--font-weight-400);
  font-size: var(--font-size-14);
  line-height: var(--line-height-17);
  cursor: pointer;
}

.staff_header_next > span,
.staff_header_prev > span {
  color: #436dfa;
}
.staff_header_next > img,
.staff_header_prev > img {
  height: 36px;
  width: 36px;
}
.staff_header_close {
  color: #da3e1b;
}
.universal_hr {
  width: 840px;
  height: 0.5px;
  color: rgba(0, 0, 0, 0.2);
  margin: 0;
}

.staff_profile_photo_container {
  padding: 20px 18px 11px 18px;
  position: relative;
  display: flex;
  align-items: center;
  gap: 12px;
}

.staff_profile_photo_info {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 8px;
}
.staff_profile_photo_image {
  width: 100px;
  height: 100px;
  border-radius: 12px;
}
.staff_profile_photo_name {
  font-weight: var(--font-weight-500);
  font-size: var(--font-size-16);
  line-height: var(--line-height-17);
  color: #121212;
}
.staff_profile_photo_index {
  font-weight: var(--font-weight-400);
  font-size: var(--font-size-14);
  line-height: var(--line-height-17);
  color: rgba(18, 18, 18, 0.8);
}
.staff_profile_photo_edit {
  width: 36px;
  height: 36px;
  position: absolute;
  top: 20px;
  right: 18px;
}

.each_info_container {
  padding: 24px 3rem 11px 3rem;
}
.each_info_container > h5 {
  font-weight: var(--font-weight-600);
  line-height: var(--line-height-17);
  font-size: 15px;
  color: rgba(18, 18, 18, 0.9);
  margin-bottom: 16px;
}
.each_field_container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
}
.each_field {
  width: 50%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 16px;
}
.each_field_text,
.each_field_text_value {
  font-weight: var(--font-weight-400);
  font-size: var(--font-size-14);
  line-height: var(--line-height-17);
}
.each_field_text {
  color: rgba(18, 18, 18, 0.5);
  margin-bottom: 0 !important;
}
.each_field_text_value {
  color: rgba(18, 18, 18, 0.9);
  margin-left: 4px;
}
