.line_container {
  min-height: 20rem;
  max-height: auto;
  position: relative;
  margin-bottom: 2rem;
  margin-top: 2rem;
}
.line {
  width: 0.6rem;
  background-color: #002bbe;
  margin-left: 5px;
  cursor: pointer;
  position: relative;
}
.dot_point_container {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 2rem;
}
.dot_point {
  width: 20px;
  height: 20px;
  background: #002bbe;
  border-radius: 50%;
  position: absolute;
}
.dot_point_title {
  margin-left: 2rem;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #121212;
}
.dot_point_subtitle {
  margin-left: 2rem;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: rgba(18, 18, 18, 0.6);
}
.dot_point > img {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  position: absolute;
  margin-left: -2px;
  cursor: pointer;
}
.dot_form_container {
  margin-left: 2rem;
  position: relative;
}
.line_hover {
  display: none;
  margin-left: -6px;
  position: absolute;
}

.line:hover .line_hover {
  display: block;
}
.save_stop_btn,
.save_stop_btn:focus {
  width: 100%;
  margin-top: 1rem;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #001a72;
  border: none;
  outline: transparent;
  display: flex;
  justify-content: center;
  background-color: #fff;
}

.button_group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 1rem;
  font-family: "Inter";
  font-style: normal;
}
.save_stop_btn1,
.save_stop_btn1:focus {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #001a72;
  border: none;
  outline: transparent;
  display: flex;
  justify-content: center;
  background-color: #fff;
}
.delete_stop_btn,
.delete_stop_btn:focus {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #ff0000;
  border: none;
  outline: transparent;
  display: flex;
  justify-content: center;
  background-color: #fff;
}
/* --------------------------------------------- */
.assign_role_container {
  width: 30rem;
  max-height: 35rem;
  min-height: 17rem;
  overflow-y: auto;
  overflow-x: hidden;
}
.assign_role_title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1.5rem;
  padding: 0.8rem;
}

.assign_role_title > img {
  cursor: pointer;
}
