/* ----------------P.Event Card------------------- */
.peventCard {
  background: #ffffff;
  border: 0.4px solid rgba(0, 0, 0, 0.15);
  box-shadow: -1px -1px 10px rgba(0, 0, 0, 0.02),
    1px 2px 32px rgba(0, 0, 0, 0.02);
  border-radius: 0.7rem;
  cursor: pointer;
  margin-bottom: 0.5rem;
}

.peventCardtop {
  padding: 0.75rem;
  display: flex;
  gap: 1rem;
  align-items: center;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.15);
}

.imgcontainer {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  display: grid;
  place-items: center;
  /* border: 0.2px solid rgba(0, 0, 0, 0.15); */
}

.imgcontainer > img {
  height: 85%;
  width: 85%;
  border-radius: 50%;
}

.issuedBookItemMidright > h6 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 0.9rem;
  line-height: 17px;
  /* identical to box height */
  margin-bottom: 0.3rem;
  color: #121212;
}

.issuedBookItemMidright > p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 17px;
  /* identical to box height */
  margin-bottom: 0 !important;
  color: rgba(18, 18, 18, 0.5);
}

.peventCardbottom {
  padding: 0.75rem;
}

.peventCardbottomp {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 19px;
  /* identical to box height, or 136% */
  margin-bottom: 0.3rem;
  text-transform: capitalize;

  color: rgba(18, 18, 18, 0.8);
}

.peventCardbottomp > span {
  font-weight: 500 !important;
  color: rgb(20, 20, 20) !important;
}

.viewRowh6 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 0.9rem !important;
  line-height: 17px;
  /* identical to box height */

  color: rgba(18, 18, 18, 0.9);
}
.desh6 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1rem !important;
  line-height: 130%;
  /* identical to box height */

  color: rgba(81, 80, 80, 0.9);
}

.viewRowp {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 17px;
  /* identical to box height */
  margin-bottom: 0.3vw !important;
  color: rgba(18, 18, 18, 0.5);
}

.viewRow {
  margin-bottom: 1.5rem;
}

.peventdetailsrighttop {
  width: 100%;
  display: flex;
  gap: 1.5%;
  padding: 1rem;
  border-bottom: 0.5px solid #c4c4c4;
}

.peventdetailsrighttopp {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 0.85rem;
  line-height: 15px;
  /* identical to box height, or 125% */
  margin-bottom: 0 !important;
  color: #121212;
}

.peventrightCard {
  background-color: #ffffff;
  border: 0.4px solid rgba(0, 0, 0, 0.15);
  box-shadow: -1px -1px 10px rgba(0, 0, 0, 0.02), 1px 2px 32px rg;
  padding: 0 !important;
}

.participant {
  width: 63%;
}

.fees {
  width: 14%;
}

.checklist {
  width: 14%;
}

.peventdetailsrightBody {
  width: 100%;
}

.peventdetailsrightBodyItem {
  width: 100%;
  display: flex;
  gap: 1.5%;
  align-items: center;
  border-bottom: 0.5px solid #c4c4c4;
}

.peventdetailsrightBodystudent {
  display: flex;
  gap: 1rem;
  align-items: center;
  padding: 0.75rem;
  width: 63%;
}

.peventdetailsrightBodyfees {
  width: 14%;
  display: flex;
  justify-content: center;
}

.peventdetailsrightBodyfees > img,
.peventdetailsrightBodychecklist > img {
  height: 1.2rem;
}

.peventdetailsrightBodychecklist {
  width: 14%;
  display: flex;
  justify-content: center;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.loader {
  display: grid;
  place-items: center;
  width: 100%;
}

.loader > img {
  height: 4rem;
}

.noData > img {
  width: 100%;
}
.noData {
  display: grid;
  place-items: center;
  width: 100%;
}

.classRow {
  display: flex;
  flex-wrap: wrap;
}

.viewRowContainer {
  display: flex;
}

.updateResult {
  width: 100%;
  padding: 0.5rem;
  background-color: #1c023b;
  color: #fff;
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  margin-top: 2rem;
  cursor: pointer;
}

.resultDeclared {
  background-color: #9183a2 !important;
  cursor: context-menu;
  color: #fff;
  margin-top: 4rem;
}
.selectPlayers {
  padding: 0.5rem;
  width: 100%;
  background: #ffffff;
  border: 0.5px solid #cacaca;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  cursor: pointer;
  position: relative;
}
.selectPosition {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.upIconn {
  position: absolute;
  left: 70%;
  top: 28%;
  height: 1.3rem;
}
.downIconn {
  position: absolute;
  left: 75%;
  top: 45%;
}

.selectPlayersActive {
  background-color: rgb(239, 239, 239);
}

.selectStudentItem,
.selectdStudentItem {
  display: flex;
  gap: 1rem;
  align-items: center;
  padding: 0.75rem;
  width: 100%;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.15);
}

.selectdStudentItem {
  padding: 0 !important;

  border-bottom: none !important;
}

.selectStudentItem:hover {
  background-color: rgb(227, 221, 221);
}

.options {
  width: 100%;
  cursor: pointer;
  list-style: none;
  box-sizing: border-box;
}

.options {
  background: #ffffff;
  border: 0.5px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.375rem;
  width: 100%;
  max-height: 50vh;
  overflow-y: scroll;
}

.itemsContainer::-webkit-scrollbar,
.options::-webkit-scrollbar {
  width: 0.5rem !important;
}

/* Track */
.itemsContainer::-webkit-scrollbar-track,
.options::-webkit-scrollbar-track {
  background: transparent !important;
  box-shadow: none !important;
}

.itemsContainer::-webkit-scrollbar-thumb,
.options::-webkit-scrollbar-thumb {
  background: rgb(183, 181, 181) !important;
  /* border-radius: 10px; */
}
