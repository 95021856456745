.editModal {
  background: #fafafa;
  border-radius: 12px;
  width: 30rem;
}

.editModalTop {
  padding: 1vw;
  display: flex;
  justify-content: space-between;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.15);
}

.editModalTop > h6 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */

  color: #121212;
}

.editModalTop img {
  cursor: pointer;
}

.editModalBody {
  padding: 1vw 1.5vw;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.profilephotocontainer {
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 0.3rem;
  padding: 0.5rem;
  border: 0.2px solid rgba(0, 0, 0, 0.05);
  box-shadow: 4px 4px 13px rgba(18, 18, 18, 0.15);
  border-radius: 50%;
  position: relative;
  margin-bottom: 1.5rem;
}

.profilephotoins {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  cursor: pointer;
}
.profilephotocontainerr {
  position: relative;
  height: 100%;
  width: 100%;
}

.form_edit_icon {
  position: absolute;
  bottom: -1rem;
  left: 70%;
  height: auto;
  width: 1.6rem;
  cursor: pointer;
}

.form_edit_icon1 {
  position: absolute;
  bottom: -0.3rem;
  left: 60%;
  height: auto;
  width: 1.6rem;
  cursor: pointer;
}

.button_main {
  width: 100%;
  min-height: 3vw;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5vw;
  background: #1c023b;
  box-shadow: 1px 2px 32px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  margin: 0;

  outline: none;
  border: none;
}

.button_main > span {
  font-family: var(--font-family);
  font-style: var(--font-style-normal);
  color: #ffffff;
  font-weight: var(--font-weight-500);
  font-size: var(--font-size-16);
}
