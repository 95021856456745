.navbar {
  width: 100%;
  background-color: #f5f5f5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5vw 1vw;
  position: sticky;
  top: 0;
  z-index: 15;
  border-bottom: 0.15rem solid #fff;
  height: 4.5rem;
}

/* .navbarbottom { */
/* margin-bottom: 0.1rem !important; */
/* } */

.navright {
  display: flex;
  align-items: center;
  gap: 3vw;
}

.topParent {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem;
  /* background-color: #ebebeb; */
}

.topParentInner {
  line-height: 19px;
  cursor: pointer;
}

.logotext {
  height: 2.5rem;
  cursor: pointer;
  border-radius: 50%;
}

.topText {
  font-size: 0.9rem;
  font-weight: 600;
  margin-top: 10px;
  color: #000;
  line-height: 0px;
}

.editMenu {
  background-color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 6px;
  box-shadow: -1px 6px 22px 0px rgb(0 0 0 / 25%);
  cursor: pointer;
}
